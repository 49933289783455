<template>
    <div>
        <Navigation />
        <img :src="bannerImg" alt="" class="banner" />
        <div class="enjoy_box">
            <div class="enjoy_ct_box">
                <p class="title_bg" :style="{ backgroundImage: `url('${title}')` }"></p>

                <p class="smark">致力于帮助更多有需求的中小企业进行图片处理、视频生成、文书指导，配合“一言一文”减少时间成本</p>
            </div>
            <div class="enjoy_ flex">
                <div class="enjoy_list flex" v-for="(item, index) in enjoy_type_list" :key="index">
                    <img :src="item.icon" alt="" class="enjoy_icon" />
                    <div class="enjoy_t">
                        <h4>{{ item.name }}</h4>
                        <p>{{ item.smark }}</p>
                    </div>
                </div>
                <button class="moner">更多功能持续开放！</button>
            </div>
        </div>
        <div class="goAi_box">
            <p class="title_bg" :style="{ backgroundImage: `url('${goAi_titile}')` }"></p>
            <div class="ai_t_box flex">
                <div class="ai_lt_box">
                    <div class="ai_bg_box"></div>
                    <button class="ai_bg_but" @click="goAiNav">《点击进入》</button>
                </div>
                <div class="computed_box">
                    <!-- <div class="ct_box"> -->
                    <!-- arrow="always" -->
                    <el-carousel :autoplay="false" trigger="click" indicator-position="none">
                        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                            <img :src="item" alt="" style="width:100%; object-fit: cover; margin: 0 auto;">
                        </el-carousel-item>
                    </el-carousel>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div class="introduce_box">
            <p class="title_bg" :style="{ backgroundImage: `url('${introduce_title}')` }"></p>
            <p class="smark">致力于帮助更多有需求的中小企业进行图片处理、视频生成、文书指导，配合“一言一文”减少时间成本!</p>
            <ul class="introduce_list flex">
                <li v-for="(item, index) in introduce_list" :key="index">
                    <span class="introduce_bg" :style="{ backgroundImage: `url('${item.icon}')` }"></span>
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.smark }}</p>
                </li>
            </ul>
        </div>
        <footerBot />
    </div>
</template>

<script>
// import { goAi } from '@/util/goAi'
export default {
    mounted() { this.getBanner() },
    data() {
        return {
            bannerImg: '',
            bannerIndex: 0,
            bannerList: [
                require('@/assets/enjoy/1.jpg'),
                require('@/assets/enjoy/2.jpg'),
                require('@/assets/enjoy/3.jpg'),
                require('@/assets/enjoy/4.jpg'),
                require('@/assets/enjoy/5.jpg'),
            ],
            title: require('@/assets/enjoy/title.png'),
            goAi_titile: require('@/assets/enjoy/goAi_titile.png'),
            introduce_title: require('@/assets/enjoy/introduce_title.png'),
            enjoy_type_list: [
                {
                    icon: require('@/assets/enjoy/a.png'),
                    name: 'AI图片背景生成',
                    smark: 'AI背景生成技术主要基于主体图片分析与处理，并根据大数据匹配对应背景合成，此技术应用场景与“智能抠图”相结合，多为电商行业产品图设计。'
                },
                {
                    icon: require('@/assets/enjoy/b.png'),
                    name: 'AI图片智能抠图',
                    smark: 'AI抠图技术主要基于图像处理技术，能够深度分析图片主体信息，理解图片逻辑结构并根据大模型进行背景剔除。'
                },
                {
                    icon: require('@/assets/enjoy/c.png'),
                    name: 'AI图片风格绘画',
                    smark: 'AI绘画技术主要基于模型算法根据设定风格与描述，生成与之匹配的不同风格的绘画图片。'
                },
                {
                    icon: require('@/assets/enjoy/d.png'),
                    name: 'AI图片风格转换',
                    smark: 'AI图片风格转换技术主要基于图像处理技术，能够深度分析图片整体元素，理解图片逻辑结构并根据大模型进行风格转化。'
                },
            ],
            introduce_list: [
                {
                    icon: require('@/assets/enjoy/ai1.png'),
                    title: 'AI图片智能抠图',
                    smark: 'AI抠图技术主要基于图像处理技术，能够深度分析图片主体信息，理解图片逻辑结构并根据大模型进行背景剔除。'
                },
                {
                    icon: require('@/assets/enjoy/ai2.png'),
                    title: 'AI图片背景生成',
                    smark: 'AI背景生成技术主要基于主体图片分析与处理，并根据大数据匹配对应背景合成，此技术应用场景与“智能抠图”相结合，多为电商行业产品图设计。'
                },
                {
                    icon: require('@/assets/enjoy/ai3.png'),
                    title: 'AI图片风格绘画',
                    smark: 'AI绘画技术主要基于模型算法根据设定风格与描述，生成与之匹配的不同风格的绘画图片。'
                },
                {
                    icon: require('@/assets/enjoy/ai4.png'),
                    title: 'AI图片黑白上色',
                    smark: 'AI图片黑白上色技术主要基于图像处理技术，能够深度分析图片整体元素，理解图片逻辑结构并根据大模型进行图片上色。'
                },
                {
                    icon: require('@/assets/enjoy/ai5.png'),
                    title: 'AI风格转换',
                    smark: 'AI风格转换技术主要基于模型算法根据设定风格与描述，生成与之匹配的不同风格的绘画图片。'
                },
            ]
        }
    },
    methods: {
        getBanner() {
            this.curlGet('/api/advert/detail', {
                mark: 'AI_banner_02'
            }).then(res => {
                if (res.data.code) {
                    this.bannerImg = res.data.data.image
                }
            })
        },
        tabShow(pre, next) {
            // console.log(pre, next);
            this.bannerIndex = pre
        },
        // 去功能选择页面
        async goAiNav() {
            // const aiStatus = await goAi()
            // if (aiStatus == 'ok') {
            this.$router.push('/aiNav')
            // } else {
            //     return this.$store.state.loginShow = true
            // }
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/enjoy'
</style>